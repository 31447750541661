import React from "react";
import {useTranslation} from "react-i18next";
import PageFrame from "../PageFrame/PageFrame";

export default function Error() {
	const {t} = useTranslation();

	return (
		<PageFrame>
			<p>{t("backoffice.common.unknownError")}</p>
		</PageFrame>
	);
}
