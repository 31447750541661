import {configureStore} from "@reduxjs/toolkit";

// IMPORT SLICES
import AppMetadataReducer from "AppSlice";
import UsersPermissionsReducer from "Views/UsersPermissionsManager/UsersPermissionsManagerSlice";
import MatchesReducer from "Views/Matches/MatchesSlice";
import UnityReducer from "./UnitySlice";

export const store = configureStore({
	reducer: {
		// Add reducers to store
		appMetadata: AppMetadataReducer,
		usersPermissions: UsersPermissionsReducer,
		matches: MatchesReducer,
		unity: UnityReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
