/* eslint-disable camelcase */
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {i18n as meshI18n} from "@pricespider-neuintel/mesh";
import {productMaintenanceAppI18n} from "@pricespider-neuintel/ps-products-admin";
import en_US from "./en_US";

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: en_US,
				mesh: en_US,
			},
		},
	});

i18n.on("languageChanged", (lng) => {
	meshI18n.changeLanguage(lng);
	productMaintenanceAppI18n.changeLanguage(lng);
});

export default i18n;
