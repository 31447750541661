import React, {lazy} from "react";
import {RouteObject} from "react-router-dom";
import App from "App";
import {Error} from "components";

const UsersPermissionsManager = lazy(() => import("Views/UsersPermissionsManager/UsersPermissionsManager"));
const Products = lazy(() => import("Views/Products/Products"));
const Matches = lazy(() => import("Views/Matches/Matches"));
const ProductMatches = lazy(() => import("components/Matches/ProductMatches/ProductMatches"));

const routes: RouteObject[] = [
	{
		path: "/",
		element: <App />,
		errorElement: <Error />,
		children: [
			{
				path: "products/*",
				element: <Products />,
			},
			{
				path: "/matches",
				element: <Matches />,
				children: [
					{
						path: ":productId",
						element: <ProductMatches />,
					},
				],
			},
			{
				path: "/user-manager",
				children: [
					{
						path: "users",
						element: <UsersPermissionsManager context="users" />,
					},
					{
						path: "roles",
						element: <UsersPermissionsManager context="roles" />,
					},
				],
			},
		],
	},
];

export default routes;
