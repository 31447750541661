import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "Store/store";
import {IProductMatch, IProductSeller} from "Types/Matches";
import {IFilter, IFilterChip} from "Types/Filter";

// Define a type for the slice state
interface IMatchesState {
	products: IProductMatch[];
	matchesFilters: IFilter[];
	productSearchFilters: IFilter[];
}

export interface IProductMatchPayload {
	productId: string;
	match: IProductSeller;
}

// Define the initial state using that type
const initialState: IMatchesState = {
	products: [],
	matchesFilters: [],
	productSearchFilters: [],
};

export const MatchesSlice = createSlice({
	name: "matches",
	initialState,
	reducers: {
		SET_PRODUCT_MATCH_DATA: (state, action: PayloadAction<IProductMatch[]>) => {
			state.products = action.payload;
		},
		UPDATE_PRODUCT_MATCH: (state, action: PayloadAction<IProductMatchPayload>) => {
			const {productId, match} = action.payload;
			const productsCopy = JSON.parse(JSON.stringify(state.products));

			for (let i = 0; i < productsCopy.length; i += 1) {
				if (productsCopy[i].id === productId) {
					const {sellers} = productsCopy[i];
					for (let j = 0; j < sellers.length; j += 1) {
						if (sellers[j].id === match.id) {
							sellers[j] = match;
							state.products = productsCopy;
							break;
						}
					}
					break;
				}
			}
		},
		SET_MATCHES_FILTERS: (state, action: PayloadAction<IFilter[]>) => {
			state.matchesFilters = action.payload;
		},
		SET_MATCHES_FILTER: (state, action: PayloadAction<IFilter>) => {
			const indexOfFilter = state.matchesFilters.findIndex((filter) => filter.name === action.payload.name);
			if (indexOfFilter >= 0) {
				state.matchesFilters[indexOfFilter] = action.payload;
			} else {
				state.matchesFilters.push(action.payload);
			}
		},
		DELETE_MATCHES_FILTER_CHIP: (state, action: PayloadAction<IFilterChip>) => {
			for (let i = 0; i < state.matchesFilters.length; i += 1) {
				if (state.matchesFilters[i].name === action.payload.filterName) {
					for (let j = 0; j < state.matchesFilters[i].options.length; j += 1) {
						if (state.matchesFilters[i].options[j].label === action.payload.optionLabel) {
							state.matchesFilters[i].options[j].selected = false;
							break;
						}
					}
					break;
				}
			}
		},
		SET_PRODUCT_SEARCH_FILTERS: (state, action: PayloadAction<IFilter[]>) => {
			state.productSearchFilters = action.payload;
		},
		SET_PRODUCT_SEARCH_FILTER: (state, action: PayloadAction<IFilter>) => {
			const indexOfFilter = state.productSearchFilters.findIndex((filter) => filter.name === action.payload.name);
			if (indexOfFilter >= 0) {
				state.productSearchFilters[indexOfFilter] = action.payload;
			} else {
				state.productSearchFilters.push(action.payload);
			}
		},
		DELETE_PRODUCT_SEARCH_FILTER_CHIP: (state, action: PayloadAction<IFilterChip>) => {
			for (let i = 0; i < state.productSearchFilters.length; i += 1) {
				if (state.productSearchFilters[i].name === action.payload.filterName) {
					for (let j = 0; j < state.productSearchFilters[i].options.length; j += 1) {
						if (state.productSearchFilters[i].options[j].label === action.payload.optionLabel) {
							state.productSearchFilters[i].options[j].selected = false;
							break;
						}
					}
					break;
				}
			}
		},
	},
});

export const {
	SET_PRODUCT_MATCH_DATA,
	UPDATE_PRODUCT_MATCH,
	SET_MATCHES_FILTERS,
	SET_MATCHES_FILTER,
	DELETE_MATCHES_FILTER_CHIP,
	SET_PRODUCT_SEARCH_FILTERS,
	SET_PRODUCT_SEARCH_FILTER,
	DELETE_PRODUCT_SEARCH_FILTER_CHIP,
} = MatchesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const GET_PRODUCT_MATCH_DATA = (state: RootState) => state.matches.products;
export const GET_PRODUCT_MATCHES = (productId: string | undefined) => (state: RootState) => {
	return state.matches.products?.find((p) => p.id === productId);
};
export const GET_MATCHES_FILTERS = (state: RootState) => state.matches.matchesFilters;
export const GET_PRODUCT_SEARCH_FILTERS = (state: RootState) => state.matches.productSearchFilters;

export default MatchesSlice.reducer;
