/* eslint-disable camelcase */

const en_US = {
	backoffice: {
		common: {
			create: "Create",
			add: "Add",
			edit: "Edit",
			save: "Save",
			update: "Update",
			back: "Back",
			cancel: "Cancel",
			delete: "Delete",
			apply: "Apply",
			ok: "Ok",
			continue: "Continue",
			finish: "Finish",
			unknownError: "Unknown error",
			yes: "Yes",
			no: "No",
			select: "Select",
			selectAll: "Select all",
			unselectAll: "Unselect all",
			search: "Search",
			loadingError: "An error occurred while loading data. Please try again.",
			noResults: "No rows matching the search query were found.",
			unauthorized: "Unauthorized",
			language: "Language",
			type: "Type",
			description: "Description",
			open: "Open",
			close: "Close",
		},
		sidebar: {
			title: "Self Service Tool",
			beta: "Beta",
			navToggleAriaLabel: "Toggle navigation bar",
			products: "Products",
			matches: "Matches",
			permissions: "Permissions",
			account: "Account",
			accountAriaLabel: "Toggle account options menu",
			logout: "Log out",
		},
		permissions: {
			missing: "Missing permissions",
			text: "You don't have the correct permissions to access this screen. Please contact your administrator for support.",
		},
		role: {
			guid: "GUID",
			name: "Name",
			userRoles: "User roles",
		},
		filter: {
			options: "Options",
			optionsAvailable: "Options available",
			countryCode: "Country Code",
			brand: "Brand",
			category: "Category",
			seller: "Seller",
			urlStatus: "URL Status",
		},
		userPermManager: {
			header: {
				title: "Users & Permissions",
				subTitle: "Management",
				addAction: "Add New",
				addRole: "Role",
				addUser: "User",
			},
			tabs: {
				usersLabel: "UsersPermissionsManager",
				rolesLabel: "Roles",
			},
			roles: {
				tableAriaLabel: "User Roles Table",
				actionEditRole: "Edit",
				actionManageUsers: "Manage Users",
				actionDeleteRole: "Delete",
				sortUsers: "Number of Users",
				sortDate: "Created on",
			},
			roleDrawer: {
				addNewRole: "Add New Role",
				roleCreated: "Role created",
				roleUpdated: "Role updated",
				roleDeleted: "Role deleted",
				basicDetails: "Basic Details",
				name: "Name",
				description: "Description",
				contentPermissions: "Content Permissions",
			},
			assignDrawer: {
				roleDetailHeader: "Role Details",
				usersHeader: "Users",
				search: "Search by username or email",
				showAssignedLabel: "Show users assigned to this role",
				currentRoles: "Current Roles",
				successAlert: "User roles updated.",
			},
			permissionGroup: {
				application: "Applications",
				bmModuleView: "View Access",
				bmShareOfSearch: "Share of Search",
				bmRAndR: "Ratings and Reviews",
				bmQAndA: "Questions and Answers",
				bmImages: "Images",
				bmActionCenter: "Action Center",
				bmGeneric: "All Modules",
				bmData: "Data",
			},
			users: {
				sendInvitation: "Send Invitation",
				name: "Name",
				email: "Email",
				roles: "Roles",
				lastActive: "Last Active",
			},
		},
		products: {
			header: "Products",
			manualUploadAction: "Manual",
			bulkUploadAction: "Bulk Upload",
			alerts: {
				updateSuccess: "Product successfully updated",
			},
			breadcrumbs: {
				maintenance: "Maintenance",
				bulkUpload: "Add / Bulk Upload",
				manualAdd: "Manual Add",
				edit: "Edit / Manual Entry",
			},
			table: {
				uploadProductsButton: "Add products",
				filters: {
					statusLabel: "Status",
					statusActive: "Active",
					statusDeleted: "Deleted",
					statusInactive: "Inactive",
					statusCandidate: "Incomplete",
					countryCodeLabel: "Country Code",
					brandNameLabel: "Brand",
					tagsLabel: "Tags",
				},
				brandNameHeader: "Brand Name",
				codeHeader: "Country Code",
				productNameHeader: "Default Product Name",
				languageHeader: "Language",
				productCodeHeader: "Product Code",
				statusHeader: "Status",
				releaseDateHeader: "Release Date",
				priceHeader: "Price",
				tagsHeader: "Tags",
				categoryHeader: "Category",
				retailersHeader: "Retailers",
				confirmDelete: "Are you sure you want to delete this product?",
				productDeleteSuccess: "{{productName}} has been deleted",
				productDeleteError: "There was an error deleting product {{productName}}",
				copiedToClipboard: "Product Code copied to clipboard",
				candidateStatus: "Incomplete",
			},
			importer: {
				switchDialog: {
					title: "{{importType}} Import",
					body: "You are in {{currentType}} Import mode, switching to {{newType}} Import will cause you to lose your current data.",
					action: "Yes, Switch",
				},
				// reuploadDialog: {
				// 	title: "Reupload",
				// 	body: "Current data will be lost if you upload a new file.",
				// 	action: "Reupload",
				// },
				saveAction: "Save",
				saving: "Saving data",
				submit: {
					fail: "{{productsCount}} products failed.",
					success: "{{productsCount}} products submitted successfully.",
				},
				defaultProductName: "Product {{value}}",
				retailerTable: {
					header: "Retailers",
					addRetailerAction: "Add Retailer",
					retailerNameHeader: "Retailer Name",
					productNameHeader: "Product Name",
					mapPriceHeader: "MAP Price",
					currencyCodeHeader: "Currency Code",
					imageUrlHeader: "Ref. Image URL",
					descriptionHeader: "Prod. Description",
					bulletsHeader: "Bullets",
				},
				drawer: {
					header: "Retailer Details",
					referenceImageUrl: "Reference Image URL",
					productDescription: "Product Description",
					bullets: "Bullets",
					labelUrl: "URL {{id}}",
					labelBullet: "Bullet {{id}}",
					addUrl: "Add Url",
					addBullet: "Add Bullet",
					urlRequirements: "Must be a valid image url",
				},
				manualImport: {
					addAction: "Add new product",
					deleteProductDialog: {
						title: "Delete product",
						label: "Are you sure you want to delete the following product:",
						content: "“{{productName}}”?",
					},
				},
				bulkImport: {
					step: {
						uploadFile: "Upload File",
						reviewColumnTitles: "Review Column Titles",
						validation: "Validation",
					},
					introMessage: "Only new products will be processed. Existing products will not be updated.",
					template: {
						header: "Bulk Upload Template",
						description: "Download a xlsx template to upload multiple products.",
						downloadAction: "Download template",
					},
					fullCatalog: {
						header: "Full Catalog Download",
						description: "Download the full catalog of products.",
						downloadAction: "Download catalog",
					},
					// cancelDialog: {
					// 	title: "Cancel Bulk Upload",
					// 	body: "Current data will be lost if you cancel the process.",
					// },
					dropzone: {
						dragAndDrop: "Drag and drop or",
						selectFile: "Select a file",
						onlyFirst20:
							"Upload a XLSX file to import multiple products. Only the first 20 results will be displayed.",
						uploading: "Uploading",
					},
					uploadError: {
						wrongFileFormat: "Wrong file format",
						fileNotSupported:
							"The selected file is not supported. Please, make sure to upload a file with the following format: <strong>CSV</strong> or <strong>XLSX</strong>",
					},
					fileInfo: {
						success: "File {{fileName}} ({{productsCount}} products).",
						error: "File {{fileName}} ({{productsCount}} products). Please fix any highlighted issues.",
						alert: "Only the first {{count}} results of the {{total}} products will be displayed.",
					},
					changeFileAction: "Change file",
					reuploadAction: "Reupload",
					productsInfo: {
						totalProducts: "{{totalProducts}} total products to be imported.",
						showErrors: "Show errors",
						success: "Bulk upload successful, please press <strong>{{action}}</strong>.",
						error: "{{productsCount}} products with errors.",
					},
					mappingTable: {
						column: {
							mpn: "MPN*",
							gtin: "GTIN*",
							countryCode: "Country Code",
							languageCode: "Language Code",
							brandName: "Brand Name",
							productName: "Product Name",
							category: "Category",
							businessRule: "Business Rule",
							customTag: "Custom Tag",
							retailerName: "Retailer Name",
							retailerUrl: "Retailer URL",
							partNumber: "Retailer SKU",
							mapPrice: "MAP Price",
							mapCurrency: "MAP Currency",
							currencyCode: "Currency Code",
							image: "Image {{index}}",
							bullet: "Bullet {{index}}",
							description: "Description",
						},
						sheetName: "Sheet {{index}}",
						unassigned: "Unassigned",
						unassign: "Unassign",
						requiredFields: "Required Fields",
						additionalFields: "Additional Fields",
						productCodeRequired: "* only one product code is required",
					},
					changeMappingAction: "Change mapping",
				},
				productForm: {
					productDetails: "Product Details",
					changeLanguageAction: "Change Language",
					additionalProductDetails: "Additional Product Details",
					addProductCode: "Add Product Code",
					fields: {
						sku: "SKU/Product ID",
						countryCode: "Country",
						brandName: "Brand Name",
						productName: "Product Name",
						productCode: {
							default: "Product Code",
							primary: "Primary Product Code",
							createCustomType: "Create Custom Product Code Types",
							primaryNotice:
								"This is your Primary Product Code and can only be modified within Settings.",
							invalidProductCode:
								"This is already your primary product code type. Please choose another type.",
							typeLabels: {
								MPN: "MPN (Manufacturer Part Number)",
								GTIN: "GTIN (Global Trade Item Number - UPC and EAN)",
								Custom: "{{codeType}} (Custom)",
							},
						},
						businessRulesTag: "Business Rules Tag",
						category: "Category",
						customTags: "Custom Tags",
						retailerName: "Retailer Name",
						retailerUrl: "Retailer URL",
						mapPrice: "MAP Price",
						currencyCode: "Currency Code",
						image: "Image {{index}}",
						description: "Description",
						releaseDate: "Release Date",
					},
					errors: {
						hasErrors: "Required Fields Incomplete",
						invalidLanguage: "Invalid language ({{lang}})",
						unknownLanguage: "Unknown language. Please select a language from the list.",
						releaseDateRequired: "A release date is required",
					},
					switchLanguageDialog: {
						title: "Change language",
						body: "Select a new language:",
					},
					deleteLanguageDialog: {
						title: "Delete language",
						label: "Are you sure you want to delete the following language:",
						content: "“{{language}}”?",
					},
					changeProductCodeDialog: {
						title: "Change Primary Product Code",
						label: "Are you sure you want to change the Primary Product Code from:",
						content: "“{{from}} to {{to}}”?",
					},
					productCodeDrawer: {
						header: "Manage Product Code Types",
						addNewCode: "Add new product code type",
						codeLabel: "Product Code Type {{index}}",
					},
				},
				retailerForm: {
					retailerDetails: "Retailer Details",
					configuredError: "No retailers configured",
					configuredTooltip: "Please contact support to setup retailers.",
					introMessage: "Choose your first retailer using the “ + “ to begin.",
					changeRetailerAction: "Change Retailer",
					addImageUrl: "Add Image",
					addBullet: "Add Bullet",
					addPromotion: "Add Promotion",
					fields: {
						sku: "SKU/Product ID",
						productName: "Product Name",
						productCode: "Retailer Product Code",
						retailerName: "Retailer Name",
						retailerUrl: "Retailer URL",
						mapPrice: "Price",
						currencyCode: "Currency Code",
						image: "Image {{index}}",
						description: "Product Description",
						referenceImageUrl: "Reference Image URL",
						productDescription: "Product Description",
						bullets: "Bullets",
						url: "URL {{id}}",
						bullet: "Bullet {{id}}",
						pricing: "Pricing",
						promotionPrice: "Promotion Price",
						startDate: "Start Date",
						endDate: "End Date",
					},
					errors: {
						unknownRetailer: "Unknown retailer. Please select a retailer from the list.",
					},
					switchRetailerDialog: {
						title: "Change retailer",
						body: "Select a new retailer:",
						label: "Retailer",
					},
					deleteRetailerDialog: {
						title: "Delete retailer",
						label: "Are you sure you want to delete the following retailer:",
						content: "“{{retailerName}}”?",
					},
				},
			},
		},
		matches: {
			productSearch: {
				header: "Product Search",
				subHeader: "Matches",
				tableAriaLabel: "Product Search Table",
				tableRowAriaLabel: "Link to product match details",
				table: {
					columnHeaders: {
						countryCode: "Country Code",
						brandName: "Brand Name",
						productName: "Product Name",
						sku: "SKU",
						category: "Category",
						retailerMatches: "Retailer Matches",
					},
				},
			},
			productMatches: {
				header: "Configured Sellers",
				subHeader: "Product Search / {{productName}}",
				tableAriaLabel: "Product Sellers Table",
				table: {
					columnHeaders: {
						seller: "Seller",
						sellerUrl: "Seller URL",
						urlStatus: "URL Status",
					},
					sellerUrlPlaceholder: "Insert URL here",
				},
				urlStatus: {
					Missing: "Missing",
					Approved: "Approved",
					Pending: "Pending",
					Declined: "Declined",
				},
				invalidPage: "Invalid Page, Please check your URL.",
			},
		},
	},
	bm: {
		app: {
			hasAccess: "Brand Monitor",
			execSummary: {
				viewer: "Executive Summary",
			},
			productSummary: {
				viewer: "Product Summary",
			},
			searchSummary: {
				viewer: "Search Summary",
			},
			shareOfSearch: {
				viewer: "Share of Search",
				addCompBrand: "Add a Competitor Brand",
			},
			price: {
				viewer: "Price",
			},
			availability: {
				viewer: "Availability",
			},
			rAndR: {
				viewer: "Ratings and Reviews",
				markReviewed: 'Mark a Review as "Reviewed"',
				editNotes: 'Add/Edit a Note to the "Notes" Column',
				addComment: "Add a Comment to the Comment Section of the Status Pop-up",
			},
			qAndA: {
				viewer: "Questions and Answers",
				editStatus: 'Edit "Answered Status"',
				addComment: "Add a Comment in the Comment Section of the Answer Status Pop-up",
			},
			images: {
				viewer: "Images",
				approveImage: "Approve/Unapprove an Image as Correct Image",
				approvePlacement: "Approve/Unapprove  Placement of Image",
			},
			content: {
				viewer: "Content",
			},
			actionCenter: {
				viewer: "Action Center",
				alertAssignTicket: "From the Alerts, assigning Tickets to users",
				ticketAssignTicket: "From Tickets, assigning Tickets",
				ticketEditStatus: "From Tickets, Editing Ticket Status",
			},
			dashboard: {
				export: "Exporting",
			},
		},
		data: {
			countries: "Countries",
			brands: "Brands",
		},
	},
	wtb: {
		app: {
			hasAccess: "Where to Buy",
		},
	},
};

export default en_US;
