import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "Store/store";
import {IUnityProduct, IUnityProductRetailer, PRODUCT_STATUS} from "Types/Unity/UnityProduct";
import {IUnityProductMetadata} from "Types/Unity/General";

export interface IDeleteRetailerRowPayload {
	retailerPSID: string;
	productId: string;
}

// Define a type for the slice state
interface IUnityState {
	products: IUnityProduct[];
	productsSearchTerm: string;
	metadata: IUnityProductMetadata | undefined;
	newProductCodes: string[];
}

// Define the initial state using that type
const initialState: IUnityState = {
	products: [],
	productsSearchTerm: "",
	metadata: undefined,
	newProductCodes: [],
};

export const UnitySlice = createSlice({
	name: "unity",
	initialState,
	reducers: {
		SET_UNITY_PRODUCTS_DATA: (state, action: PayloadAction<IUnityProduct[]>) => {
			state.products = action.payload;
		},
		SET_PRODUCTS_SEARCH_TERM: (state, action: PayloadAction<string>) => {
			state.productsSearchTerm = action.payload;
		},
		SET_UNITY_METADATA: (state, action: PayloadAction<IUnityProductMetadata | undefined>) => {
			state.metadata = action.payload;
		},
		UPDATE_PRODUCTS_GROUP: (state, action: PayloadAction<IUnityProduct[]>) => {
			const products = action.payload;
			const {PSCPID} = products[0];

			const index = state.products.findIndex((product: IUnityProduct) => product.PSCPID === PSCPID);
			const productsCopy = state.products.filter((product: IUnityProduct) => product.PSCPID !== PSCPID);

			state.products = [...productsCopy.slice(0, index), ...products, ...productsCopy.slice(index)];
		},
		DELETE_PRODUCT: (state, action: PayloadAction<string>) => {
			const productToDelete = state.products.findIndex((product) => product.PSCPID === action.payload);
			state.products[productToDelete].status = PRODUCT_STATUS.DELETED;
		},
		DELETE_RETAILER: (state, action: PayloadAction<IDeleteRetailerRowPayload>) => {
			const productsCopy = JSON.parse(JSON.stringify(state.products));

			for (let i = 0; i < productsCopy.length; i += 1) {
				if (productsCopy[i].PSCPID === action.payload.productId) {
					const indexOfRetailer = productsCopy[i].retailers?.findIndex(
						(retailer: IUnityProductRetailer) => retailer.retailerPSID === action.payload.retailerPSID,
					);
					if (indexOfRetailer !== -1) {
						productsCopy[i].retailers?.splice(indexOfRetailer, 1);
					}
					state.products = productsCopy;
					break;
				}
			}
		},
		SET_NEW_PRODUCT_CODE_TYPES: (state, action: PayloadAction<string[]>) => {
			state.newProductCodes = action.payload;
		},
	},
});

export const {
	SET_UNITY_PRODUCTS_DATA,
	SET_UNITY_METADATA,
	UPDATE_PRODUCTS_GROUP,
	DELETE_PRODUCT,
	DELETE_RETAILER,
	SET_NEW_PRODUCT_CODE_TYPES,
} = UnitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const GET_UNITY_PRODUCTS_DATA = (state: RootState) => state.unity.products;
export const GET_UNITY_METADATA = (state: RootState) => state.unity.metadata;
export const GET_NEW_PRODUCT_CODE_TYPES = (state: RootState) => state.unity.newProductCodes;

export default UnitySlice.reducer;
