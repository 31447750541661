import React from "react";
import {Outlet} from "react-router-dom";
import styled from "@emotion/styled";
import {Loading} from "@pricespider-neuintel/mesh";
import {useAppSelector} from "Store/hooks";
import {GET_USER_DATA} from "AppSlice";

const MainWrapperStyled = styled.div`
	background: rgba(0, 71, 255, 0.04);
	flex: 1;
	position: relative;
	width: 100%;
	min-height: 100%;
`;

export default function MainContent(): React.ReactElement {
	// Aliases
	//

	// Store State
	const userData = useAppSelector(GET_USER_DATA);

	// Local State
	//

	return <MainWrapperStyled>{!userData ? <Loading /> : <Outlet />}</MainWrapperStyled>;
}
