import React, {useCallback, useMemo} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Chip} from "@mui/material";
import styled from "@emotion/styled";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import {SideNav, ISideNavRoute, Icon, useAlertCenter, IAddAlertProps} from "@pricespider-neuintel/mesh";
import {useAppSelector, useAppDispatch} from "Store/hooks";
import {GET_SIDE_NAV_OPEN, GET_USER_DATA, GET_USER_PERMISSION, SET_SIDE_NAV_OPEN, SET_USER_DATA} from "AppSlice";
import {IError} from "Types/Error";
import {LogoutUser} from "AppUtils";

const ContentWrapperStyled = styled.div`
	display: flex;
	min-height: 100vh;
	position: relative;
	padding-left: 3rem;
	transition: padding-left 0.25s ease-in-out;

	&.open {
		padding-left: 18.75rem;
	}
`;

function CustomProductSVG() {
	return (
		<svg width="24" height="24" viewBox="-1 3 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_658_49837" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_658_49837)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.5 15.5H15.5V4.5H13V11L10 9.5L7 11V4.5H4.5V15.5ZM6 14V12.5H10V14H6Z"
					fill="black"
					fillOpacity="0.12"
				/>
				<path d="M8.5 8.562L10 7.812L11.5 8.562V4.5H8.5V8.562Z" fill="black" fillOpacity="0.54" />
				<path
					d="M8.5 8.562L10 7.812L11.5 8.562V4.5H8.5V8.562ZM6 14V12.5H10V14H6ZM4.5 17C4.08333 17 3.72933 16.854 3.438 16.562C3.146 16.2707 3 15.9167 3 15.5V4.5C3 4.08333 3.146 3.72933 3.438 3.438C3.72933 3.146 4.08333 3 4.5 3H15.5C15.9167 3 16.2707 3.146 16.562 3.438C16.854 3.72933 17 4.08333 17 4.5V15.5C17 15.9167 16.854 16.2707 16.562 16.562C16.2707 16.854 15.9167 17 15.5 17H4.5ZM4.5 15.5H15.5V4.5H13V11L10 9.5L7 11V4.5H4.5V15.5Z"
					fill="black"
					fillOpacity="0.54"
				/>
			</g>
		</svg>
	);
}

enum RoutePathsEnum {
	PRODUCTS = "/products",
	MATCHES = "/matches",
	USER_MANAGER = "/user-manager/users",
}

interface IPageFrameProps {
	children: React.ReactNode;
}

export default function PageFrame(props: IPageFrameProps) {
	// Aliases
	const {children} = props;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const {addAlert} = useAlertCenter();

	// Store State
	const sideNavOpen = useAppSelector(GET_SIDE_NAV_OPEN);
	const userData = useAppSelector(GET_USER_DATA);
	const canViewProducts = useAppSelector(GET_USER_PERMISSION("sst.app.productManager.viewer"));

	// Local State
	const logo = useMemo(() => {
		const redirectUrl = "/";
		return <Icon type="SSTLogo" align="right" onClick={() => navigate(redirectUrl)} />;
	}, [navigate]);

	const title = useMemo(() => {
		return t("backoffice.sidebar.title");
	}, [t]);

	const routes: ISideNavRoute[] = useMemo(() => {
		return [
			{
				label: t("backoffice.sidebar.products"),
				route: RoutePathsEnum.PRODUCTS,
				icon: CustomProductSVG(),
				visible: canViewProducts,
				disabled: !canViewProducts,
				children: [],
				chip: <Chip label={t("backoffice.sidebar.beta")} color="primary" size="small" />,
			},
			{
				label: t("backoffice.sidebar.matches"),
				route: RoutePathsEnum.MATCHES,
				icon: <HubOutlinedIcon />,
				visible: false,
				disabled: true,
				children: [],
			},
			{
				label: t("backoffice.sidebar.permissions"),
				route: RoutePathsEnum.USER_MANAGER,
				icon: <PeopleAltOutlinedIcon />,
				visible: false,
				disabled: true,
				children: [],
			},
		];
	}, [t, canViewProducts]);

	const selectedRoute = useMemo(() => {
		for (const path of Object.values(RoutePathsEnum)) {
			if (location.pathname.startsWith(path)) {
				return path;
			}
		}

		return location.pathname;
	}, [location]);

	const handleToggleSideNav = useCallback(() => {
		dispatch(SET_SIDE_NAV_OPEN(!sideNavOpen));
	}, [dispatch, sideNavOpen]);

	const handleLogout = useCallback(async () => {
		const response = await LogoutUser();
		if ((response as IError).error) {
			const alertData = {
				type: "basic",
				severity: "error",
				duration: 6000,
				anchorOrigin: {vertical: "bottom", horizontal: "center"},
				text: (response as IError).errorMessage,
			} as IAddAlertProps;
			addAlert(alertData);
		} else {
			dispatch(SET_USER_DATA(undefined));
			navigate("/", {replace: true});
		}
	}, [dispatch, navigate, addAlert]);

	return (
		<ContentWrapperStyled className={sideNavOpen ? "open" : ""}>
			<SideNav
				open={sideNavOpen}
				routes={routes}
				selectedRoute={selectedRoute}
				userData={userData}
				onToggle={handleToggleSideNav}
				onLogout={handleLogout}
				logo={logo}
				title={title}
				footerRoutes={[]}
			/>
			{children}
		</ContentWrapperStyled>
	);
}
