/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "Store/store";
import {IFilter, IFilterChip} from "Types/Filter";
import {IUser, IRole, IPermission, IPermissionGroup, IRoleSort} from "Types/Role";

export enum PERMISSIONS_DRAWER {
	ADD_NEW_ROLE = "ADD_NEW_ROLE",
	EDIT_ROLE = "EDIT_ROLE",
	ASSIGN_ROLE = "ASSIGN_ROLE",
}

interface IPermissionsDrawerState {
	open: boolean;
	type: PERMISSIONS_DRAWER | undefined;
	data: any;
}

export interface IUpdateUsersPayload {
	usersToAdd: IUser[];
	usersToRemove: IUser[];
	roleGuid: string;
}

export interface IOpenPermissionsDrawerPayload {
	type: PERMISSIONS_DRAWER;
	data: any;
}

// Define a type for the slice state
interface IUsersPermissionsState {
	users: IUser[];
	roles: IRole[];
	permissions: IPermission[] | undefined;
	permissionGroups: IPermissionGroup[] | undefined;
	permissionsDrawer: IPermissionsDrawerState;
	roleSearch: string;
	roleSort: IRoleSort | undefined;
	usersManagementFilters: IFilter[];
}

// Define the initial state using that type
const initialState: IUsersPermissionsState = {
	users: [],
	roles: [],
	permissions: undefined,
	permissionGroups: undefined,
	permissionsDrawer: {
		open: false,
		type: undefined,
		data: null,
	},
	roleSearch: "",
	roleSort: undefined,
	usersManagementFilters: [],
};

export const UsersPermissionsManagerSlice = createSlice({
	name: "usersPermissions",
	initialState,
	reducers: {
		SET_USERS_DATA: (state, action: PayloadAction<IUser[]>) => {
			state.users = action.payload;
		},
		SET_ROLES_DATA: (state, action: PayloadAction<IRole[]>) => {
			state.roles = action.payload;
		},
		SET_PERMISSIONS_DATA: (state, action: PayloadAction<IPermission[] | undefined>) => {
			state.permissions = action.payload;
		},
		SET_PERMISSIONGROUPS_DATA: (state, action: PayloadAction<IPermissionGroup[] | undefined>) => {
			state.permissionGroups = action.payload;
		},
		ADD_ROLE: (state, action: PayloadAction<IRole>) => {
			state.roles = [action.payload, ...state.roles];
		},
		UPDATE_ROLE: (state, action: PayloadAction<IRole>) => {
			for (let i = 0; i < state.roles.length; i += 1) {
				if (state.roles[i].guid === action.payload.guid) {
					state.roles[i] = action.payload;
					break;
				}
			}
		},
		DELETE_ROLE: (state, action: PayloadAction<string>) => {
			state.roles = state.roles.filter((role) => role.guid !== action.payload);
		},
		OPEN_PERMISSIONS_DRAWER: (state, action: PayloadAction<IOpenPermissionsDrawerPayload>) => {
			const {type, data} = action.payload;
			state.permissionsDrawer = {
				...state.permissionsDrawer,
				open: true,
				type,
				data,
			};
		},
		CLOSE_PERMISSIONS_DRAWER: (state) => {
			state.permissionsDrawer = {
				...state.permissionsDrawer,
				open: false,
				type: undefined,
				data: null,
			};
		},
		SET_ROLE_SEARCH: (state, action: PayloadAction<string>) => {
			state.roleSearch = action.payload;
		},
		SET_ROLE_SORT: (state, action: PayloadAction<IRoleSort | undefined>) => {
			state.roleSort = action.payload;
		},
		SET_USERS_MANAGER_FILTERS: (state, action: PayloadAction<IFilter[]>) => {
			state.usersManagementFilters = action.payload;
		},
		SET_USERS_MANAGER_FILTER: (state, action: PayloadAction<IFilter>) => {
			const indexOfFilter = state.usersManagementFilters.findIndex(
				(filter) => filter.name === action.payload.name,
			);
			if (indexOfFilter >= 0) {
				state.usersManagementFilters[indexOfFilter] = action.payload;
			} else {
				state.usersManagementFilters.push(action.payload);
			}
		},
		DELETE_USERS_MANAGER_FILTER_CHIP: (state, action: PayloadAction<IFilterChip>) => {
			for (let i = 0; i < state.usersManagementFilters.length; i += 1) {
				if (state.usersManagementFilters[i].name === action.payload.filterName) {
					for (let j = 0; j < state.usersManagementFilters[i].options.length; j += 1) {
						if (state.usersManagementFilters[i].options[j].label === action.payload.optionLabel) {
							state.usersManagementFilters[i].options[j].selected = false;
							break;
						}
					}
					break;
				}
			}
		},
	},
});

export const {
	SET_USERS_DATA,
	SET_ROLES_DATA,
	SET_PERMISSIONS_DATA,
	SET_PERMISSIONGROUPS_DATA,
	ADD_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	OPEN_PERMISSIONS_DRAWER,
	CLOSE_PERMISSIONS_DRAWER,
	SET_ROLE_SEARCH,
	SET_ROLE_SORT,
	SET_USERS_MANAGER_FILTER,
	SET_USERS_MANAGER_FILTERS,
	DELETE_USERS_MANAGER_FILTER_CHIP,
} = UsersPermissionsManagerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const GET_USERS_DATA = (state: RootState) => state.usersPermissions.users;
export const GET_ROLES_DATA = (state: RootState) => state.usersPermissions.roles;
export const GET_PERMISSIONS_DATA = (state: RootState) => state.usersPermissions.permissions;
export const GET_PERMISSIONGROUPS_DATA = (state: RootState) => state.usersPermissions.permissionGroups;
export const GET_PERMISSIONS_DRAWER = (state: RootState) => state.usersPermissions.permissionsDrawer;
export const GET_ROLE_SEARCH = (state: RootState) => state.usersPermissions.roleSearch;
export const GET_ROLE_SORT = (state: RootState) => state.usersPermissions.roleSort;
export const GET_USERS_MANAGER_FILTERS = (state: RootState) => state.usersPermissions.usersManagementFilters;

export default UsersPermissionsManagerSlice.reducer;
