import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "Store/store";
import {IUser} from "Types/Role";

// Define a type for the slice state
interface IAppState {
	user: IUser | undefined;
	sideNavOpen: boolean;
}

// Define the initial state using that type
const initialState: IAppState = {
	user: undefined,
	sideNavOpen: true,
};

export const AppMetadataSlice = createSlice({
	name: "appMetadata",
	initialState,
	reducers: {
		SET_USER_DATA: (state, action: PayloadAction<IUser | undefined>) => {
			state.user = action.payload;
		},
		SET_SIDE_NAV_OPEN: (state, action: PayloadAction<boolean>) => {
			state.sideNavOpen = action.payload;
		},
	},
});

export const {SET_USER_DATA, SET_SIDE_NAV_OPEN} = AppMetadataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const GET_USER_DATA = (state: RootState) => state.appMetadata.user;
export const GET_SIDE_NAV_OPEN = (state: RootState) => state.appMetadata.sideNavOpen;
export const GET_USER_PERMISSION = (permission: string) => (state: RootState) => {
	return state.appMetadata.user?.permissions[permission] || false;
};

export default AppMetadataSlice.reducer;
