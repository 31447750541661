import {t} from "i18next";
import {IError} from "Types/Error";
import {IUser} from "Types/Role";

const hostToApiMap: {[key: string]: string} = {
	localhost: "http://localhost:3001",
	"sst-test.pricespider.com": "https://sst-test-api.pricespider.com",
	"sst.pricespider.com": "https://sst-api.pricespider.com",
};

const hostToEnvMap: {[key: string]: string} = {
	localhost: "LOCAL",
	"sst-test.pricespider.com": "DEV",
	"sst.pricespider.com": "PROD",
};

export function getEnv(): string {
	const {hostname} = window.location;
	return hostToEnvMap[hostname];
}

export function isProd(): boolean {
	const env = getEnv();
	return env === "PROD";
}

export function getBaseUrl(): string {
	const {hostname} = window.location;
	return hostToApiMap[hostname];
}

export async function GetInsightsJwt(): Promise<string | undefined> {
	try {
		const insightsUrl = isProd()
			? "https://insights.pricespider.com/v1/my/user-token"
			: "https://usw-dev-insights.pricespider.com/v1/my/user-token";
		const request = await fetch(insightsUrl, {
			credentials: "include",
		});

		if (request.ok) {
			const data = await request.json();
			return data.jwt;
		}
		return undefined;
	} catch (error) {
		return undefined;
	}
}

export async function GetUserData(insightsJwt?: string): Promise<IUser | IError> {
	const baseUrl = getBaseUrl();

	try {
		const token = `?token=${insightsJwt}`;
		const request = await fetch(`${baseUrl}/api/auth/userdata${insightsJwt ? token : ""}`, {
			credentials: "include",
		});

		if (request.status === 401) {
			return {
				error: true,
				errorMessage: `${t("backoffice.common.unauthorized")}`,
			};
		}

		if (request.ok) {
			const data = await request.json();
			return data.user;
		}

		throw new Error("An unexpected error occurred");
	} catch (error) {
		console.log("getUserData() error: ", error);
		return {
			error: true,
			errorMessage: JSON.stringify(error),
		};
	}
}

export async function LogoutUser(): Promise<{success: boolean} | IError> {
	const baseUrl = getBaseUrl();

	try {
		const request = await fetch(`${baseUrl}/api/auth/logout`, {
			method: "POST",
			credentials: "include",
		});

		if (request.ok) {
			return await request.json();
		}

		throw new Error("An unexpected error occurred");
	} catch (error) {
		console.error("LogoutUser() error: ", error);
		return {
			error: true,
			errorMessage: JSON.stringify(error),
		};
	}
}
