import React, {useEffect} from "react";
import "./App.css";
import {LicenseInfo} from "@mui/x-license-pro";
import {I18nextProvider} from "react-i18next";
import {AlertCenterProvider, IAddAlertProps, Loading, useAlertCenter} from "@pricespider-neuintel/mesh";
import MainContent from "components/Main/Main";
import PageFrame from "components/PageFrame/PageFrame";
import {IError} from "Types/Error";
import {IUser} from "Types/Role";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {GET_USER_DATA, SET_USER_DATA} from "AppSlice";
import {GetInsightsJwt, GetUserData} from "AppUtils";
import i18n from "./i18n/i18n";

LicenseInfo.setLicenseKey(
	"ffd24578b4fe96a0e20cc7149ddd6f80Tz02ODY1NyxFPTE3MTgzNzg2MzU3MTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

function Application(): React.ReactElement {
	// Aliases
	const dispatch = useAppDispatch();
	const {addAlert} = useAlertCenter();

	// Store State
	const user = useAppSelector(GET_USER_DATA);

	// Local State
	//

	useEffect(() => {
		async function getInsightsJwt(): Promise<string | undefined> {
			return GetInsightsJwt();
		}

		async function getUserData(jwt: string | undefined): Promise<void> {
			const response = await GetUserData(jwt);
			if ((response as IError).error) {
				const alertData = {
					type: "basic",
					severity: "error",
					duration: 6000,
					anchorOrigin: {vertical: "bottom", horizontal: "center"},
					text: (response as IError).errorMessage,
				} as IAddAlertProps;
				addAlert(alertData);
			} else {
				dispatch(SET_USER_DATA(response as IUser));
			}
		}

		if (!user) {
			getInsightsJwt().then((response) => {
				getUserData(response);
			});
		}
	}, [dispatch, user, addAlert]);

	return (
		<PageFrame>
			<React.Suspense fallback={<Loading />}>
				<MainContent />
			</React.Suspense>
		</PageFrame>
	);
}

export default function App(): React.ReactElement {
	return (
		<AlertCenterProvider>
			<I18nextProvider i18n={i18n}>
				<Application />
			</I18nextProvider>
		</AlertCenterProvider>
	);
}
