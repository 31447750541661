import {IUnityTracing} from "./General";
import {ICountryCode} from "../ProductImporter";

export interface IUnityProduct {
	name: string;
	map: IProductMap[] | null;
	promotions: IProductPromotion[] | null;
	clientProductReference: string;
	productCodes: IProductCode[];
	localeCode: string;
	description: string;
	productReferenceUrl: string | null;
	imageUrls: IProductImageUrl[];
	bullets: IProductBullet[];
	retailers: IUnityProductRetailer[] | null;
	alternatePSCodes: IProductAltPSCode[] | null;
	productAttributes?: IProductAttribute[];
	releaseDate: string | null;
	tags: IProductTag[];
	status: PRODUCT_STATUS;
	PSCBID: string;
	PSCategory: string | null;
	PSCPID: string;
	PSPID: string;
	countryCode?: ICountryCode;
	tracing?: IUnityTracing;
	[key: string]: unknown;
}

export enum PRODUCT_STATUS {
	ACTIVE = "ACTIVE",
	DELETED = "DELETED",
	CANDIDATE = "CANDIDATE",
	INACTIVE = "INACTIVE",
}

export interface IUnityProductRetailer {
	PSROSID: string;
	PSCPID: string;
	retailerPSID: string;
	retailerPartNumber?: string;
	productName: string;
	sku?: string; // FIXME
	imageUrls?: IProductImageUrl[];
	bullets?: IProductBullet[];
	productDescription?: string;
	map: IProductMap[] | null;
	promotions?: IProductPromotion[];
	retailerUrl?: string; // FIXME
	altRefCode?: string[]; // FIXME
	currencyCode?: string; // FIXME
}

export interface IProductMap {
	amount?: number;
	currencyCode: string;
}

export interface IProductPromotion {
	effectiveStartDate: string;
	effectiveEndDate: string;
	map: IProductMap[];
}

export interface IProductCode {
	name: string;
	code: string;
}

export interface IProductImageUrl {
	url: string;
	index: number;
}

export interface IProductBullet {
	bullet: string;
	index: number;
}

export interface IProductAltPSCode {
	sourceSystem: string;
	sourceProductId: string;
}

export interface IProductAttribute {
	[key: string]: string | number | IProductAttribute;
}

export interface IProductTag {
	tagType: string;
	tags: string[];
}
